'use client';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Box, Container, SimpleGrid } from '@chakra-ui/layout';
import { Hide } from '@chakra-ui/media-query';
import { groupBy } from 'lodash';
import {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import slugify from 'react-slugify';

import DiscoverySection from '@/app/_components/discovery/DiscoverySection';
import PromotionsCard from '@/app/_components/promotions-card/PromotionsCard';
import { getImageLink } from '@/services/link.service';
import { CallToAction } from '@/types/home/home.types';
import { Range } from '@/types/request/request.types';
import {
  Special,
  SpecialCategory,
  SpecialFilter,
} from '@/types/specials/specials.types';
import { VehicleType } from '@/types/used-cars/used-cars.types';

import BannerSection from './BannerSection';
import './PromotionsPage.scss';
import FilterSection from './FilterSection';

type SpecialsLanding = {
  main: {
    title: string;
    description: string;
    image: string;
    mobileImage: string;
    url: string;
  };
  footer: {
    footer_call_to_action: CallToAction;
    footer_call_to_action_image: string;
  };
};

interface Props {
  specialsLanding: SpecialsLanding;
  specials: Special[];
  ranges: Range[];
  specialsCategories: any[];
  defaultFilters: { [key: string]: string };
  hrefBase?: string;
}

type SimpleGridComponentProps = {
  className?: string;
  children: ReactNode;
  columns?: number[];
  spacingX?: string[];
  spacingY?: string[];
  paddingX?: { [key: string]: string };
};

const tags = [
  { label: 'New', value: 'new' },
  { label: 'Cash Back', value: 'cashback' },
  { label: 'Finance', value: 'finance' },
];

const SimpleGridComponent = ({
  className,
  children,
  columns = [1, 1, 2, 3],
  spacingX = ['0px', '0px', '40px', '40px'],
  spacingY = ['40px', '40px', '80px', '80px'],
  paddingX = {},
}: SimpleGridComponentProps) => {
  return (
    <SimpleGrid
      className={className}
      columns={columns}
      spacingX={spacingX}
      spacingY={spacingY}
      paddingX={paddingX}
    >
      {children}
    </SimpleGrid>
  );
};

const PromotionsPage = ({
  specialsLanding,
  specials,
  specialsCategories,
  ranges,
  defaultFilters,
}: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<SpecialFilter>({
    model: '',
    ...defaultFilters,
  });
  const [isRenderingClientSide, setIsRenderingClientSide] = useState(false);
  let [filteredSpecials, setFilteredSpecials] = useState(specials);

  function getModel(id: number): string | null {
    let model = ranges.findIndex((range) => range.id === id);

    if (!model || !id) {
      return null;
    }

    return slugify(ranges[model].name);
  }

  const getFilteredSpecials = () => {
    let updatedSpecials = specials;

    //filter by tags dropdown
    if (selectedFilter['tag']) {
      switch (selectedFilter['tag']) {
        case 'new':
          updatedSpecials = updatedSpecials.filter(
            (special) => special.vehicle_type === VehicleType.New,
          );
          break;
        case 'cashback':
          updatedSpecials = updatedSpecials.filter(
            (special) => special.cashback,
          );
          break;
        case 'finance':
          updatedSpecials = updatedSpecials.filter(
            (special) =>
              special.payment_terms ||
              special.deposit ||
              special.residual ||
              special.interest_rate,
          );
          break;
      }
    }

    // filter by category
    if (selectedFilter['category']) {
      updatedSpecials = updatedSpecials.filter((special) => {
        return (
          selectedFilter['category'] &&
          special?.special_category?.id === +selectedFilter['category']
        );
      });
    }

    // filter by model
    if (selectedFilter['model']) {
      updatedSpecials = updatedSpecials.filter(
        (special) =>
          selectedFilter['model'] &&
          +selectedFilter['model'] === +special?.range?.id,
      );
    }

    return updatedSpecials;
  };

  if (!isRenderingClientSide) {
    // do the first filter of results on the server side to prevent the
    // content from changing when the useEffect runs on the client
    filteredSpecials = getFilteredSpecials();
  }

  useEffect(() => {
    setIsRenderingClientSide(true);
    setFilteredSpecials(getFilteredSpecials());
  }, [specials, selectedFilter]);

  const getCountByRange = useCallback(
    (rangeId: string | number) => {
      return filteredSpecials.filter((special: Special) => {
        return special.range?.id === rangeId;
      }).length;
    },
    [filteredSpecials],
  );

  const getSelectedFilterTitles = useCallback(() => {
    const filterTitles: { tag?: string; model?: string; category?: string } =
      {};

    if (selectedFilter['tag']) {
      const selectedTag = tags.find(
        (tag) => tag.value === selectedFilter['tag'],
      );

      if (selectedTag) {
        filterTitles['tag'] = selectedTag.label;
      }
    }

    if (selectedFilter['model']) {
      const selectedRange = ranges.find(
        (model) =>
          selectedFilter['model'] && +model.id === +selectedFilter['model'],
      );

      if (selectedRange) {
        filterTitles['model'] = selectedRange.name;
      }
    }

    if (selectedFilter['category']) {
      const selectedCategory = categoriesOptions.find(
        (cat) => `${cat.value}` === selectedFilter['category'],
      );

      if (selectedCategory) {
        filterTitles['category'] = selectedCategory?.label;
      }
    }

    return filterTitles;
  }, [specials, selectedFilter]);

  const rangesOptions: { label: string; value: string; slug: string }[] =
    useMemo(() => {
      const specialsGroupByRangeId = groupBy(filteredSpecials, 'range.id');

      return ranges
        .map((range: { id: string | number; name: string }) => {
          if (!specialsGroupByRangeId[range.id]) return;

          return {
            label: `${range.name} (${getCountByRange(range.id)})`,
            value: range.id + '',
            slug: slugify(range.name),
          };
        })
        .filter(
          (item): item is { label: string; value: string; slug: string } =>
            !!item,
        );
    }, [specials, filteredSpecials]);

  const categoriesOptions = useMemo(() => {
    let filteredCategories = filteredSpecials
      .map((special) =>
        specialsCategories.find(
          (category) => category.id === special.special_category?.id,
        ),
      )
      .filter((item) => item);

    // remove duplicates by constructing a set
    const filteredCategoriesSet = new Set<SpecialCategory>(filteredCategories);

    filteredCategories = Array.from(filteredCategoriesSet);

    const options = filteredCategories.map((category: SpecialCategory) => {
      return {
        label: category.title,
        value: category.id.toString(),
        slug: slugify(category.value),
      };
    });

    return options;
  }, [specials, filteredSpecials]);

  const handleFilterChange = (newFilter: SpecialFilter) => {
    setSelectedFilter(newFilter);

    if (newFilter.model && !newFilter.category && !newFilter.tag) {
      const selectedOption = rangesOptions.find(
        (option) => option.value === newFilter.model,
      );
      const newUrl = `/promotions/${selectedOption?.slug}`;

      window.history.pushState({}, '', newUrl);
    } else if (newFilter.category && !newFilter.model && !newFilter.tag) {
      const selectedOption = categoriesOptions.find(
        (option) => option.value === newFilter.category,
      );
      const newUrl = `/promotions/categories/${selectedOption?.slug}`;

      window.history.pushState({}, '', newUrl);
    } else if (
      (!newFilter.model || newFilter.model.length == 0) &&
      (!newFilter.category || newFilter.category.length == 0)
    ) {
      window.history.pushState({}, '', '/promotions');
    }
  };

  const getSelectedFilterTitlesList = (): string[] => {
    const result = [];
    const selectedFilterTitles = getSelectedFilterTitles();

    for (const key of ['model', 'category', 'tag']) {
      const title = selectedFilterTitles[key as 'model' | 'category' | 'tag'];

      if (title) {
        result.push(title);
      }
    }
    return result;
  };

  return (
    <>
      <BannerSection props={specialsLanding?.main} />
      <Suspense fallback={null}>
        <FilterSection
          selectedFilter={selectedFilter}
          selectedFilterTitles={getSelectedFilterTitles()}
          onSetSelectedFilter={handleFilterChange}
          rangesOptions={rangesOptions}
          specialsCategoriesOptions={categoriesOptions}
          tags={tags}
        />

        <Box className="darkBg">
          <Container
            maxWidth={{ base: '100%', md: '1328px' }}
            alignContent={'center'}
            paddingX={{ base: 6 }}
            paddingTop={{ lg: '15px' }}
            paddingY={{ base: '80px' }}
          >
            <Hide breakpoint="(max-width: 768px)">
              <Breadcrumb color={'white'} marginBottom={'45px'}>
                <BreadcrumbItem>
                  <BreadcrumbLink href="#">Promotions</BreadcrumbLink>
                </BreadcrumbItem>

                {getSelectedFilterTitlesList().length && (
                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href="#">
                      {getSelectedFilterTitlesList()[0]}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                )}
              </Breadcrumb>
            </Hide>
            <SimpleGridComponent className="promotionsList">
              {filteredSpecials &&
                filteredSpecials?.map((item: Special) => (
                  <PromotionsCard
                    item={{ ...item, image: getImageLink(item?.image) ?? '' }}
                    key={item.id}
                    hrefBase={
                      getModel(item?.range?.id)
                        ? `/promotions/${getModel(item.range.id)}`
                        : '/promotions'
                    }
                  />
                ))}
            </SimpleGridComponent>
          </Container>
        </Box>
        <DiscoverySection
          footer_call_to_action={
            specialsLanding?.footer?.footer_call_to_action
              ? specialsLanding?.footer?.footer_call_to_action
              : null
          }
          footer_call_to_action_image={getImageLink(
            specialsLanding?.footer?.footer_call_to_action_image,
          )}
        />
      </Suspense>
    </>
  );
};

export default PromotionsPage;
